
.confirm-unsubscribe-container {

    .image {
        max-height: 400px;
    }

    .buttons-container {
        .btn-no {
            margin-right: 16px;
        }
    }

    // colors {
    //     color: #CBA97E; color: #D59858; color: #B1A584; 
    //     color: #9C7A56; color: #907D66; color: #B85444; 
    //     color: #5C9598; color: #A6613C; color: #B4B05A; 
    //     color: #7DC1CB; color: #B4CCC9; color: #35AB71; 
    //     color: #F8BD39; color: #D05355; color: #D663D7; 
    //     color: #37328e; color: #aa1777'
    // }

}